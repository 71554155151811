<template>
  <div class="px-2 px-md-12">
    <page-title-block page-name="Uncollected C&Cs" :store-name="storeName"></page-title-block>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :items="uncollectedCATS"
          :headers="headers"
          :items-per-page="-1"
          sort-by="lastUpdateTime"
          show-select
          v-model="selectedCATS"
        >
          <template v-slot:[`item.date`]="{ item }">
            <span>{{item.date | longDate}}</span>
          </template>
          <template v-slot:[`item.lastUpdateTime`]="{ item }">
            <span v-if="item.lastUpdateTime">{{item.lastUpdateTime | longDate}}</span>
            <span v-else>Never</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PageTitleBlock from '@/components/PageTitleBlock'

export default {
  name: 'UncollectedDashboard',
  components: { PageTitleBlock },
  data () {
    return {
      loading: false,
      error: null,
      timer: '',
      headers: [
        {
          text: 'Order Id',
          value: 'orderId'
        },
        {
          text: 'Customer Name',
          value: 'customerName'
        },
        {
          text: 'Phone Number',
          value: 'number'
        },
        {
          text: 'Date Created',
          value: 'date'
        },
        {
          text: 'Last Updated',
          value: 'lastUpdateTime'
        }
      ],
      selectedCATS: []
    }
  },
  filters: {
    menuSummariser: (text) => {
      return text ? text.split(' ').map(word => word.replace(/[aeiou]/ig, '')).map(word => word.slice(0, 3)).join(' ') : ''
    }
  },
  computed: {
    ...mapState('instore', ['stores', 'smsSentCATS', 'timeSetCATS']),
    storeId: function () {
      return this.$route.params.storeId
    },
    storeName: function () {
      const storeDetails = this.stores.find(item => item.storeId === this.storeId)
      return storeDetails ? storeDetails.storeName : 'Unknown'
    },
    uncollectedCATS: function () {
      return [
        ...this.smsSentCATS,
        ...this.timeSetCATS
      ]
    }
  },
  created () {
    this.fetchData()
    this.timer = setInterval(this.fetchData, 60000)
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData'
  },
  methods: {
    showSearchPopup: function () {
      this.showSearch = true
    },
    fetchData: async function () {
      this.error = null
      this.loading = true
      const token = await this.$auth.getTokenSilently()
      return this.$store.dispatch('instore/loadUncollectedData', { storeId: this.storeId, token })
        .then(() => (this.loading = false))
        .catch(err => (this.error = err))
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>

</style>
